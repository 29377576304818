<template>
    <div class=" min-w-[1380px] w-[1380px] m-auto overflow-auto">
        <div class=" flex h-[calc(100vh-0.25rem)] ">
            <nav class=" xl:w-64 max-md:hidden sticky top-0 bg-[#F6F6F6]">
                <div class=" font-bold text-xl inline-flex items-center justify-center gap-4 bg-white w-full py-2">
                    <a href="/">
                        <img src="/avatar.jpg" class=" w-10 h-10 rounded-full" alt="一个橙子" />
                    </a>
                    <a href="/" class="bg-[linear-gradient(120deg,#9a5416,#ffaa3f)] bg-clip-text text-transparent">
                        一个橙子
                    </a>
                </div>
                <PersionInfo />
                <!-- 备案 -->
                <div class=" absolute bottom-2 w-full p-2 rounded">
                    <div class=" flex flex-col text-sm">
                        <div>
                            <img src="/gongan.png" class="w-[16px] float-left mr-1" alt="" srcset="">
                            <a href="https://beian.mps.gov.cn/#/query/webSearch?code=61012602000058" rel="noreferrer"
                                title="陕公网安备61012602000058号" target="_blank">陕公网安备61012602000058号</a>
                        </div>
                        <a href="https://beian.miit.gov.cn/" title="陕ICP备2023011778号">陕ICP备2023011778号</a>
                    </div>
                </div>
            </nav>
            <main class=" flex-1 w-0 bg-white px-2 overflow-auto no-scrollbar">
                <div class="overflow-auto m-auto max-w-screen-xl">
                    <slot></slot>
                </div>
            </main>
            <div class=" w-[300px] shadow sticky top-0 px-2 bg-white">
                <div class=" flex py-4 justify-around border border-dashed my-2">
                    <ClientOnly>
                        <template v-if="loginStatus != '1'">
                            <a :href="`/user/login?redirect_url=${href}`" class=" hover:text-blue-500">
                                <button class=" hover:underline">去登录</button>
                            </a>
                            <a href="/user/register" class=" hover:text-blue-500">
                                <button class=" hover:underline">去注册？</button>
                            </a>
                        </template>
                        <template v-else>
                            <a href="/user/center" class=" hover:text-blue-500">
                                <button class=" hover:underline">用户中心</button>
                            </a>
                        </template>
                    </ClientOnly>
                </div>
            </div>
        </div>
    </div>


    <ClientOnly>
        <div class="fixed inset-0 z-50" v-if="isProd && isLeaf"></div>
    </ClientOnly>
</template>
<script setup lang="ts">
    import { usePageLeave } from "@vueuse/core"
    const isProd = useRuntimeConfig().public.isProd
    
    let href = ref()
    let loginStatus = ref()
    if (import.meta.client) {
        loginStatus.value = sessionStorage.getItem("login")
        href.value = location.href
    }

    // 监测页面离开
    const isLeaf = usePageLeave()

    onMounted(() => {
        if (isProd) {
            try {
                setInterval(() => { Function("debugger")() }, 50)
            } catch (error) {

            }
        }
    })
</script>
<style lang="less" scoped>
    .index-layout {
        // background: #f6f6f6;

        &__title {
            height: 64px;
            color: #222226;
            background: #fff;
            box-shadow: 0 2px 4px 0 rgb(0 0 0 / 5%);
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-left: 20px;

            position: sticky;
            top: 0px;
            z-index: 1;

            .extra {
                // width: 120px;
                margin-right: 20px;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                > ::slotted(span) {
                    margin-right: 10px;
                }

                .user-info {
                    cursor: pointer;
                }

                a {
                    text-decoration: none;
                    color: #000;
                }
            }
        }

        &__content {
            max-width: 900px;
            margin: 0 auto;
            margin-top: 10px;
        }
    }

    .header-search {
        :deep(.el-input.is-disabled .el-input__inner) {
            cursor: pointer !important;
        }
    }
</style>